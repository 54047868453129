<template>
  <div class="welcome">
    <v-container>
      <div class="text-center mb-12">
        <img :src="$store.getters['app/logo']" height="80" />
      </div>
      <!-- <v-img src="@/assets/images/logo-welcome.svg" width="163" center class="mx-auto logo" /> -->
      <template v-if="userIsAuthorized">
        <div class="welcome-title">Привет, {{user.first_name || 'Анонимный пользователь'}} !</div>
        <v-btn color="primary" block x-large class="mb-6" :to="{name: 'Home'}">Продолжить</v-btn>
        <v-btn text class="bottom-text" @click="logout">Выйти из учетной записи</v-btn>
      </template>

      <template v-else>
        <v-btn color="primary" class="mb-6" depressed block x-large :to="{name: 'Login'}">Войти</v-btn>
        <v-btn
          v-if="!$store.getters['app/alternativeAuth']"
          color="primary"
          class="mb-6"
          block
          x-large
          outlined
          :to="{name: 'Registration'}"
        >Регистрация</v-btn>
        <v-btn text :to="{name: 'Home'}">Войти без регистрации</v-btn>
      </template>
    </v-container>
  </div>
</template>

<script>
export default {
  computed: {
    userIsAuthorized() {
      return this.$store.getters["user/isAuthorized"];
    },
    user() {
      return this.$store.state.user && this.$store.state.user.profile;
    }
  },
  methods: {
    logout() {
      this.$store.commit("user/logout");
    }
  }
};
</script>

<style lang="scss" scoped>
.welcome-title {
  // color: #fff;
  font-size: 20px;
  font-weight: 500;
  margin: 0 0 30px;
}

.logo {
  margin-bottom: 100px;
}

.welcome {
  text-align: center;
  /* display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  background: #333333 url(~@/assets/images/welcome-background.svg) no-repeat
    center;
  background-size: cover; */
}

.bottom-text {
  text-decoration: underline;
  // color: #b8b8b8;
}
</style>